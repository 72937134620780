module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#ffffff","showSpinner":false},
    },{
      plugin: require('../node_modules/@sb-konzept/gatsby-source-storyblok/gatsby-browser.js'),
      options: {"plugins":[],"transform":{"components":{}},"generateFragments":[{"fragment":"StoryblokMarkdown","addToQuery":"childMarkdownRemark { htmlAst }"},{"fragment":"StoryblokBox","images":[{"path":["backgroundImage"],"type":"fluid","maxWidth":1920,"quality":90,"fragmentType":"withWebp"}]},{"fragment":"StoryblokNewsItem","images":[{"path":["image"],"type":"fluid","maxWidth":352,"maxHeight":198,"quality":90,"fragmentType":"withWebp"}]},{"fragment":"StoryblokPerson","images":[{"path":["image"],"type":"fluid","maxWidth":768,"maxHeight":768,"quality":90,"fragmentType":"withWebp"}]},{"fragment":"StoryblokTabs","images":[{"path":["dropdownAdornmentImage"],"type":"fluid","maxWidth":1920,"quality":90,"fragmentType":"withWebp"}]},{"fragment":"StoryblokHeader","images":[{"path":["logo"],"type":"fixed","height":45,"quality":90,"fragmentType":"withWebp"}]}],"createPreviewPage":true,"version":"published","accessToken":"XvxzYdv7l0BKx5ySurVjVQtt","adminToken":"VN6vHIJQaKtMYZV6XcyMXwtt-45597-9gXM9sR-z6XtyzG-ahuz","spaceId":"51149","timeout":30000},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bayerische EliteAkademie","short_name":"BEA","start_url":"/","background_color":"#000000","theme_color":"#0873d7","display":"standalone","icon":"src/assets/images/icon.png","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"e3c0904d594956494c2f6941367f51c9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
