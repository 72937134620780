import React from 'react'
import { useEditableProps } from '@sb-konzept/gatsby-source-storyblok'
import Box from 'components/Box'
import NavigationItem from 'components/NavigationItem'

function Footer (props) {
  const { content } = props
  const editableProps = useEditableProps(content)

  return (
    <Box
      as="footer"
      p={2}
      display="flex"
      justifyContent="center"
      flexDirection={{ xs: 'column', sm: 'row' }}
      {...editableProps}
    >
      {content.items.map(item => (
        <NavigationItem
          key={item.id || item._uid}
          link={item.link}
          label={item.label}
          icon={item.icon}
          color="inherit"
        />
      ))}
    </Box>
  )
}

export default Footer
