/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import 'typeface-open-sans'
import CssBaseline from '@material-ui/core/CssBaseline'
import { StoryblokComponentProvider } from '@sb-konzept/gatsby-source-storyblok'

import {
  createGenerateClassName,
  ThemeProvider,
  jssPreset,
  StylesProvider,
  install
} from '@material-ui/styles'
import { create } from 'jss'

import theme from 'theme'

install()

const jss = create({
  ...jssPreset(),
  // We define a custom insertion point that JSS will look for injecting the styles in the DOM.
  insertionPoint: 'jss-insertion-point'
})

// Add the JSS insertion point comment to the top of the head.
export const onClientEntry = () => {
  const styleNode = window.document.createComment('jss-insertion-point')
  window.document.head.insertBefore(styleNode, window.document.head.firstChild)
}

// Remove the server-side injected CSS.
export const onInitialClientRender = () => {
  const ssStyles = window.document.getElementById('server-side-jss')
  return ssStyles && ssStyles.parentNode.removeChild(ssStyles)
}

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: false,
  productionPrefix: 'c'
})

export const wrapRootElement = ({ element }) => (
  <StylesProvider jss={jss} generateClassName={generateClassName}>
    <StyledThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <StoryblokComponentProvider
          registry={
            require('storyblok-components') /* eslint-disable-line global-require */
              .default
          }
        >
          <CssBaseline />
          {element}
        </StoryblokComponentProvider>
      </ThemeProvider>
    </StyledThemeProvider>
  </StylesProvider>
)
