import React, { useMemo } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { usePageContext } from 'components/PageContext'

function getUrl (currentPage, pages, link) {
  if (link.linktype !== 'story') return link.cached_url

  const targetPages = pages.edges.filter(
    ({ node }) => node.context.uuid === link.id
  )
  const { node: page } = currentPage.lang
    ? targetPages.find(({ node }) => node.context.lang === currentPage.lang)
    : targetPages.find(({ node }) => node.context.lang === 'default')

  return page.path
}

function StoryblokLink (props) {
  const { link, ...rest } = props
  const { page } = usePageContext()
  const { pages } = useStaticQuery(graphql`
    {
      pages: allSitePage(filter: { context: { uuid: { regex: "/.+/" } } }) {
        edges {
          node {
            path
            context {
              uuid
              lang
            }
          }
        }
      }
    }
  `)

  const linkData = useMemo(() => {
    if (!link) return null
    const url = getUrl(page, pages, link)

    const internal = /^\/(?!\/)/.test(url)
    const anchor = /^#/.test(url)

    const Component = !internal ? 'a' : Link
    const linkProps = {
      [!internal ? 'href' : 'to']: url,
      ...(!internal
        && !anchor && { target: '_blank', rel: 'noopener noreferrer' })
    }
    return { linkProps, Component }
  }, [link, page, pages])

  if (!linkData) return null
  const { linkProps, Component } = linkData

  return React.createElement(Component, { ...linkProps, ...rest })
}

export default StoryblokLink
