const { createMuiTheme } = require('@material-ui/core/styles')

const beaBlue = {
  100: '#05cbef',
  200: '#00aece',
  300: '#0091ce',
  400: '#0072ce',
  500: '#004ece',
  600: '#0540a0',
  700: '#01296a',
  800: '#010c1e'
}

function createToolbarHeights (createProps) {
  return {
    '@media (min-width:0px) and (orientation: landscape)': createProps(
      48 * 1.5
    ),
    '@media (min-width:600px)': createProps(64 * 1.5),
    ...createProps(56 * 1.5)
  }
}

const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#0670B0'
    },
    secondary: {
      main: '#ff6600',
      contrastText: '#ffffff'
    },
    green: {
      main: '#80c41c'
    },
    red: {
      main: '#ff1900'
    },
    blue: {
      ...beaBlue,
      contrastText: '#ffffff'
    },
    grey: {
      main: '#727A7C',
    },
    gradient: {
      light: '#00adb0',
      main: '#00b7ff',
      dark: '#0077f1',
      contrastText: '#ffffff'
    }
  },
  mixins: {
    toolbar: createToolbarHeights(height => ({ minHeight: height })),
    toolbarHeights: createToolbarHeights
  },
  shape: { borderRadius: 0 },
  typography: {
    fontFamily: ['"Univers"', 'sans-serif'].join(','),
    useNextVariants: true
  },
  overrides: {
    MuiTypography: {
      h1: { fontWeight: 700, textTransform: 'none' },
      h2: { fontWeight: 700, textTransform: 'none' },
      h3: { fontWeight: 700, textTransform: 'none' },
      h4: { fontWeight: 700, textTransform: 'none' },
      h5: { fontWeight: 700 },
      h6: { fontWeight: 700 },
      button: { fontWeight: 700 }
    },
    MuiToolbar: {},
    MuiDivider: {
      root: {
        marginBottom: 16,
        backgroundColor: ({ theme }) => theme.palette.common.black
      }
    },
    MuiButton: {
      root: {
        fontWeight: 700,
        textTransform: 'none'
      },
      outlined: {
        border: '2px solid currentColor'
      },
      outlinedPrimary: {
        border: ({ theme }) => `2px solid ${theme.palette.primary.main}`,
        '&:hover': {
          border: null
        }
      },
      outlinedSecondary: {
        border: ({ theme }) => `2px solid ${theme.palette.secondary.main}`,
        '&:hover': {
          border: null
        }
      }
    }
  }
})

module.exports = muiTheme
