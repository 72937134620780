import React, { useCallback, useState, useMemo } from 'react'
import Image from 'gatsby-image'
import { useEditableProps } from '@sb-konzept/gatsby-source-storyblok'
import { useTheme } from '@material-ui/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Hidden from '@material-ui/core/Hidden'

import Box from 'components/Box'
import Link from 'components/Link'

function Header (props) {
  const { content } = props
  const { items, logo, logoLink } = content
  const editableProps = useEditableProps(content)
  const theme = useTheme()
  const [open, setOpen] = useState(false)

  const hideDrawer = useCallback(() => {
    setOpen(false)
  }, [])
  const showDrawer = useCallback(() => {
    setOpen(true)
  }, [])

  return (
    <>
      <Drawer anchor="right" open={open} onClose={hideDrawer}>
        <div
          tabIndex={0}
          role="button"
          onClick={hideDrawer}
          onKeyDown={hideDrawer}
        >
          <Box width={250}>
            <List>
              {items.map(item => (
                <ListItem
                  button
                  key={item.id || item._uid}
                  component={Link}
                  link={item.link}
                >
                  <ListItemText primary={item.label} />
                </ListItem>
              ))}
            </List>
          </Box>
        </div>
      </Drawer>
      <AppBar position="sticky">
        <Box width="100%" maxWidth={theme.breakpoints.width('xl')} mx="auto">
          <Toolbar>
            <Box flexGrow={1}>
              <Link link={logoLink}>
                {logo.localFile.childImageSharp ? (
                  <Image fixed={logo.localFile.childImageSharp?.fixed} />
                ) : (
                  <Box as="img" height={45} src={logo.localFile.publicURL} />
                )}
              </Link>
            </Box>
            <Hidden smDown initialWidth="md">
              {items.map(item => (
                <Button
                  key={item.id || item._uid}
                  component={Link}
                  link={item.link}
                  color="inherit"
                >
                  {item.label}
                </Button>
              ))}
            </Hidden>
            <Hidden mdUp initialWidth="md">
              <IconButton
                color="inherit"
                aria-label="Menu"
                onClick={showDrawer}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
          </Toolbar>
        </Box>
      </AppBar>
    </>
  )
}

export default Header
