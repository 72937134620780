import React from 'react'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Box from 'components/Box'
import Link from 'components/Link'
import Youtube from 'mdi-material-ui/Youtube'
import { Linkedin as LinkedinBox } from 'mdi-material-ui'
import { Facebook as FacebookBox } from "mdi-material-ui";
import { Instagram as InstagramBox } from "mdi-material-ui";

const ICONS = {
  Youtube,
  LinkedinBox,
  FacebookBox,
  InstagramBox
}

export default function NavigationItem (props) {
  const {
    link, label, icon, ...rest
  } = props
  const Icon = ICONS[icon]

  if (label) {
    return (
      <Button component={Link} link={link} {...rest}>
        {icon && Icon && (
          <Box display="flex" mr={1}>
            <Icon />
          </Box>
        )}
        {label}
      </Button>
    )
  }

  if (!label && icon && Icon) {
    return (
      <IconButton component={Link} link={link} {...rest}>
        <Icon />
      </IconButton>
    )
  }

  return null
}
