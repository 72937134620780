import React from 'react'

import { makeStyles } from '@material-ui/styles'
import Box from 'components/Box'

const useStyles = makeStyles(theme => ({
  anchor: {
    ...theme.mixins.toolbarHeights(height => ({
      marginTop: -height,
      paddingBottom: height
    }))
  }
}))

function StoryblokHiddenAnchor (props) {
  const { content } = props
  const classes = useStyles()
  const { hash } = content
  
  return (
    <Box
      className={classes.anchor}
      as="span"
      fontSize={0}
      height={0}
      display="block"
      id={hash}
    >
      &nbsp;
    </Box>
  )
}

export default StoryblokHiddenAnchor
