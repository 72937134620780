/* eslint-disable global-require */
import React from 'react'
import { ComponentRegistry } from '@sb-konzept/gatsby-source-storyblok'

const registry = new ComponentRegistry()

registry.configure({
  components: {
    grid: React.lazy(() => import('./Grid')),
    button: React.lazy(() => import('./Button')),
    'contact-form': React.lazy(() => import('./ContactForm')),
    milestones: React.lazy(() => import('./Milestones')),
    carousel: React.lazy(() => import('./Carousel')),
    'latest-news': React.lazy(() => import('./LatestNews')),
    table: React.lazy(() => import('./Table')),
    'hidden-anchor': require('./HiddenAnchor').default,
    footer: require('./Footer').default,
    'news-item': {
      component: React.lazy(() => import('./NewsItem')),
      editable: React.lazy(() => import('./NewsItem/editable'))
    },
    header: {
      component: require('./Header').default,
      editable: React.lazy(() => import('./Header/editable'))
    },
    collapsible: {
      component: React.lazy(() => import('./Collapsible'))
      // editable: React.lazy(() => import('./Collapsible/editable'))
    },
    tabs: {
      component: React.lazy(() => import('./Tabs')),
      editable: React.lazy(() => import('./Tabs/editable'))
    },
    box: {
      component: React.lazy(() => import('./Box')),
      editable: React.lazy(() => import('./Box/editable'))
    },
    'person-profile': {
      component: React.lazy(() => import('./PersonProfile')),
      editable: React.lazy(() => import('./Person/editable'))
    },
    person: {
      component: React.lazy(() => import('./Person')),
      editable: React.lazy(() => import('./Person/editable'))
    },
    'single-person': {
      component: React.lazy(() => import('./SinglePerson')),
      editable: React.lazy(() => import('./SinglePerson/editable'))
    },
    milestone: {
      component: React.lazy(() => import('./Milestone')),
      editable: React.lazy(() => import('./Milestone/editable'))
    },
    'people-group-collapsible': {
      component: React.lazy(() => import('./PeopleGroupCollapsible')),
      editable: React.lazy(() => import('./PeopleGroupCollapsible/editable'))
    },
    'single-people-group': {
      component: React.lazy(() => import('./SinglePeopleGroup')),
      editable: React.lazy(() => import('./SinglePeopleGroup/editable'))
    },
    'rich-text': {
      component: React.lazy(() => import('./RichText')),
      editable: React.lazy(() => import('./RichText/editable'))
    },
    quote: {
      component: React.lazy(() => import('./Quote')),
      editable: React.lazy(() => import('./Quote/editable'))
    },
    'press-release': {
      component: React.lazy(() => import('./PressRelease')),
      editable: React.lazy(() => import('./PressRelease/editable'))
    },
    'press-releases': {
      component: React.lazy(() => import('./PressReleases')),
      editable: React.lazy(() => import('./PressReleases/editable'))
    }
  },
  previews: {
    'people-group': React.lazy(() => import('./previews/PeopleGroup')),
    person: React.lazy(() => import('./previews/Person')),
    page: React.lazy(() => import('./previews/Page')),
    layout: React.lazy(() => import('./previews/Layout')),
    'news-item': React.lazy(() => import('./previews/NewsItem')),
    'press-release': React.lazy(() => import('./previews/PressRelease'))
  }
})

export default registry
